import React, {useEffect} from "react";
import Header from "../../components/Header/Header";
import "./testCreation.css"
import CreationTabBar from "./atoms/CreationTabBar";
import CreationContent from "./atoms/CreationContent";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchQuestions,
    fetchScales,
    fetchScaleSettings, fetchStaticTags, fetchTests,
    loadTests,
    setCurrentTestById, startLocalQuestionsScales
} from "../../Redux/reducers/mainReducer";
import {useLocation} from "react-router-dom";
import Popup from "../../components/Popup/Popup";

export default function TestCreation(){
    let dispatch = useDispatch();
    let currentTest = useSelector(state => state.main.currentTest)
    let tests = useSelector(state => state.main.tests)
    let isTestsLoaded = useSelector(state => state.main.isTestsLoaded)
    useEffect(()=>{
       loadPage();
    },[])

    function loadPage(){
        if(!currentTest._id){
            dispatch(fetchTests());
        }
    }



    useEffect(() => {
        if(tests){

            if(window.location.pathname !== '/test'){
                let parts = location.pathname.split('/');
                if(parts.length === 3){
                    let id = parts[parts.length - 1];
                    console.log(id)
                    dispatch(setCurrentTestById(id))
                    if(id){
                        dispatch(fetchScales(id))
                        dispatch(fetchQuestions(id))
                        dispatch(fetchScaleSettings(id))

                    }

                }
                if(parts.length === 4){
                    let id = parts[parts.length - 2];
                    dispatch(setCurrentTestById(id))
                    if(id){
                        dispatch(fetchScales(id))
                        dispatch(fetchQuestions(id))
                        dispatch(fetchScaleSettings(id))

                    }
                    dispatch(fetchStaticTags());
                }
            }
        }

    }, [isTestsLoaded === true])
    let location = useLocation();
    let [currentWindow, setCurrentWindow] = React.useState(0)

    useEffect(() =>{
        let parts = location.pathname.split('/');
        let link = parts[parts.length - 1]
        if(link === 'scales'){
            setCurrentWindow(1)
        }

        if(link === 'questions'){
            setCurrentWindow(2);
        }
    }, [])



    return (
        <div className="testCreationHolder">
            <Header/>
            <div className="testCreation">
                <div className="container">
                    <CreationTabBar currentWindow={currentWindow} setCurrentWindow={setCurrentWindow}/>
                    <CreationContent currentWindow={currentWindow}/>
                    <Popup/>
                </div>
            </div>
        </div>
    )
}