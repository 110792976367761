import React from "react";
import {useDispatch} from "react-redux";
import {
    changeLocalMethodName,
    changeLocalScaleDescription, changeLocalScaleInterpretation, changeLocalScaleRecommendation,
    changeLocalSpecialistDescription,
    changeLocalStudentDescription, changeLocalStudentInstruction, updateLocalQuestionsScales,
    updateScale,
    updateTestField
} from "../../../Redux/reducers/mainReducer";

export default function DropdownTextfield(props){

    let dispatch = useDispatch();
    const handleChange = e => {
        switch (props.type){

            case 'CHANGE_SCALE_DESC':
                dispatch(changeLocalScaleDescription(props.id1, e.target.value))
                break
            case 'studentDescription':
                dispatch(changeLocalStudentDescription(e.target.value))
                break;
            case 'specialistDescription':
                dispatch(changeLocalSpecialistDescription(e.target.value))
                break;
            case 'CHANGE_SCALE_INTERPRETATION':
                dispatch(changeLocalScaleInterpretation(props.id1, props.id2, e.target.value))
                dispatch(updateLocalQuestionsScales())
                break
            case 'CHANGE_SCALE_RECOMMENDATION':
                dispatch(changeLocalScaleRecommendation(props.id1, props.id2, e.target.value))
                dispatch(updateLocalQuestionsScales())
                break
            case 'studentInstruction':
                dispatch(changeLocalStudentInstruction(e.target.value))
                break;
        }
    }
    const handleChangeBlur = e => {
        let fields;
        switch (props.type){

            case 'CHANGE_SCALE_DESC':
                fields = {
                    "description": e.target.value
                }
                dispatch(updateScale(props.scaleId, props.currentTest._id, fields))
                break
            case 'studentDescription':
                fields = {
                    "desStudent": e.target.value
                }
                break;
            case 'specialistDescription':
                fields = {
                    "desTeacher": e.target.value
                }
                break;
            case 'CHANGE_SCALE_INTERPRETATION':
                let levelsI = props.currentTest.scales[props.id1].levels;
                levelsI[props.id2].interpretation = e.target.value
                fields = {
                    "levels": levelsI
                }
                dispatch(updateScale(props.scaleId, props.currentTest._id, fields))
                break;
            case 'CHANGE_SCALE_RECOMMENDATION':
                let levelsR = props.currentTest.scales[props.id1].levels;
                levelsR[props.id2].recommendation = e.target.value
                fields = {
                    "levels": levelsR
                }
                dispatch(updateScale(props.scaleId, props.currentTest._id, fields))
                break;
            case 'studentInstruction':
                fields = {
                    "desStudents": e.target.value
                }
                break;
            default:


                break
        }
        if(fields){
            dispatch(updateTestField(props.currentTest._id, fields))
        }
    }
    return(
        <div onClick={(e)=>{e.currentTarget.firstElementChild.focus()}} className="dropdownTextfield">
            <textarea onBlur={handleChangeBlur} id={props.id} value={props.value} onChange={handleChange} placeholder=" "/>
            <label>{props.label}</label>
        </div>
    )
}