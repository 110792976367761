import React from "react";
import profile from '../img/profile.jpg'
import arrowDown from '../img/arrowDown.svg'

export default function ProfileHeaderButton(){
    return(
    // <div className="profileHeaderButton">
    //     <button>
    //         <div className="circleHolder">
    //             <img alt="" src={profile}/>
    //         </div>
    //         <img alt="" src={arrowDown}/>
    //     </button>
    // </div>
        <a href="https://testu.online/dashboard" className="profileButton">Профиль</a>
    )
}