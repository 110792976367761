import React, {useEffect} from "react";
import GeneralTestCreation from "./GeneralTestCreation";
import ScalesTestCreation from "./ScalesTestCreation";
import QuestionTestCreation from "./QuestionTestCreation";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchQuestions,
    fetchScales,
    fetchScaleSettings,
    fetchStaticTags,
    fetchTests, startLocalQuestionsScales, updateLocalQuestionsScales
} from "../../../Redux/reducers/mainReducer";
export default function CreationContent(props){
    let dispatch = useDispatch();
    let currentTest = useSelector(state => state.main.currentTest)
    let isScalesLoaded = useSelector(state => state.main.isScalesLoaded)


    useEffect(()=>{
        dispatch(startLocalQuestionsScales())
    }, [isScalesLoaded])

    useEffect(()=>{
        dispatch(updateLocalQuestionsScales)
    }, [currentTest.scales])

    document.addEventListener('click', (e) =>{
       let checker = 0;
       for(let i = 0; i < e.path.length; i++){
           if(e.path[i].classList){
               if(!e.path[i].classList.contains('addNewScaleButton')){
                   checker++;
               }
           } else {
               checker++;
           }
       }
        if(e.path.length === checker){
            checker = 0;
            let toClose = document.getElementsByClassName('addNewScaleButtonDropdown');

            for(let i = 0; i < toClose.length; i++){
                setTimeout(() =>{
                    toClose[i].classList.remove('opened')
                }, 1)

            }
        }


   })

    return (
        <div className="creationContent">
            {props.currentWindow === 0 ?
                <GeneralTestCreation currentTest={currentTest}/>
            : ''}
            {props.currentWindow === 1 ?
                <ScalesTestCreation currentTest={currentTest}/>
                : ''}
            {props.currentWindow === 2 ?
                <QuestionTestCreation currentTest={currentTest}/> : ''}
        </div>
    )
}
