import { combineReducers } from "redux"
import {createStore, applyMiddleware} from "redux"
import thunk from "redux-thunk"


import {composeWithDevTools} from "@redux-devtools/extension";
import mainReducer from "./reducers/mainReducer";


const rootReducer = combineReducers({
    main: mainReducer
})

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))

