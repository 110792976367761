import React, {useEffect} from "react";
import search from "../img/search.svg";
import sixDots from "../img/sixDots.svg";
import DropdownInput from "./DropdownInput";
import arrowDown from "../img/arrowDown.svg";
import lineType from "../img/lineType.svg";
import oneAnwer from "../img/oneAnswer.svg";
import multiAnswerImg from "../img/multiAnswer.svg";
import addButton from "../img/addTest.svg";
import copyButton from "../img/copyButton.svg";
import deleteButton from "../img/deleteButton.svg";
import roundClose from "../img/roundClose.svg";
import {useDispatch, useSelector} from "react-redux";
import {
    changeLocalIntegral,
    changeLocalQuestionIsText,
    changeLocalQuestionName,
    changeLocalQuestionQuantity,
    changeLocalQuestionTextTitle,
    changeLocalTypeScale,
    copyQuestion,
    createQuestion,
    deleteQuestion,
    startLocalQuestionsScales,
    updateLocalQuestionsScales,
    updateQuestion,
    updateScale
} from "../../../Redux/reducers/mainReducer";


export default function QuestionTestCreation(props){

    function isTextHandler(e, id1, id2, selection, questionId, currentTestId){
        console.log(e.target.checked)
        dispatch(changeLocalQuestionIsText(id1, id2, e.target.checked))
        let selection1 = selection;
        selection1[id2].isText = e.target.checked;
        let fields = {
            "selection": selection1
        }
        dispatch(updateQuestion(questionId, currentTestId, fields))
    }

    function handleQuantityChange(e, id1){
        dispatch(changeLocalQuestionQuantity(id1, e.target.value))
    }
    function handleQuantityBlur(e, questionId, question){
        let value = question.selection.length;
        if(e.target.value !== ''){
            value = e.target.value;
        }
        let fields = {
            "answerQuantity": value
        }
        dispatch(updateQuestion(questionId, props.currentTest._id, fields))
    }
    let questions = document.getElementsByClassName('question_holder');

    function integralHandler(questionId, testId, question, questionOrderId){

        let fields = {
            integral: !question.integral
        }
        dispatch(changeLocalIntegral(questionOrderId, !question.integral))
        dispatch(updateQuestion(questionId, testId, fields))


    }
    function copyQuestionCallback(testId, question){
        dispatch(copyQuestion(testId, question))
        setTimeout(()=>{
            dispatch(updateLocalQuestionsScales())
        }, 200)


    }
    function deleteQuestionCallback(testId, question){
        dispatch(deleteQuestion(testId, question))
        setTimeout(()=>{
            dispatch(updateLocalQuestionsScales())
        }, 200)

    }
    let dispatch = useDispatch();
    let currentTest = useSelector(state => state.main.currentTest);
    useEffect(() =>{
        if(currentTest.scales){
            dispatch(startLocalQuestionsScales())
        }
    }, [currentTest.scales])
    useEffect(() => {

        dispatch(updateLocalQuestionsScales())
    }, [])

    function addAnswerToQuestion(id, question){
        let selection = question.selection;
        selection.push(
            {
                "question": "",
                "value": ""
            }
        )
        let fields = {
            "selection": selection
        }
        dispatch(updateQuestion(id, currentTest._id, fields))
    }
    function getPoints(id){
        let points = 0;

        if(currentTest.questionsScales){
            if(currentTest.questionsScales[id]){
                if(currentTest.questionsScales[id].levels) {
                    currentTest.questionsScales[id].levels.map((level, levelId) => {
                        if (level.range.from > points) {
                            points = level.range.from
                        }
                        if (level.range.to > points) {
                            points = level.range.to
                        }
                    })
                }
                console.log(currentTest.settings[0].countingMethodId)
                if(currentTest.settings[0].countingMethodId === '6290d9bd20e16534e7c09c70'){
                    currentTest.questions.map((question, questionId) => {
                        if(question.typeScale === currentTest.questionsScales[id]._id){
                            console.log(question)
                            if(question.selection && question.questionType !== 'MultiSelection'){
                                let reduce = 0;
                                question.selection.map((select, selectId) => {
                                    if(select.value !== null){
                                        if(select.value > reduce){
                                            reduce = select.value
                                        }
                                    }
                                })
                                points-= reduce;
                            }
                            if(question.range){
                                let reduce = 0;
                                if(question.range.from !== null && question.range.from){
                                    if(question.range.from > reduce){
                                        reduce = question.range.from
                                    }
                                }
                                if(question.range.to !== null){
                                    if(question.range.to > reduce){
                                        reduce = question.range.to
                                    }
                                }
                                points-= reduce;
                            }
                            if(question.questionType === 'MultiSelection'){
                                let newArray = []
                                for(let i = 0; i < question.selection.length; i++){
                                    newArray.push(question.selection[i].value);
                                }
                                let sortedArray = newArray.sort((a, b) => a - b);
                                let minus = 0;
                                let toSort = 0;
                                if(sortedArray.length < question.answerQuantity){
                                    toSort = sortedArray.length;
                                } else {
                                    toSort = question.answerQuantity;
                                }
                                for(let i = 0; i < toSort; i++){
                                    if(sortedArray[sortedArray.length - 1 - i]){
                                        minus += sortedArray[sortedArray.length - 1 - i];
                                    }
                                }
                                points -= minus;
                            }
                        }
                    })
                } else {
                    if(searchQuestionsArray[id]){
                        if((searchQuestionsArray[id].selection && (searchQuestionsArray[id].questionType !== 'MultiSelection'))){
                            let value = 0;
                            searchQuestionsArray[id].selection.map((select, selectId) => {
                                if(select.value !== null){
                                    if(select.value > value){
                                        value = select.value
                                    }
                                }
                            })
                            return points - value;
                        }
                        if((searchQuestionsArray[id].range)){
                            let value = 0;
                            if(searchQuestionsArray[id].range.from !== null && searchQuestionsArray[id].range.from){
                                if(searchQuestionsArray[id].range.from > value){
                                    value = searchQuestionsArray[id].range.from
                                }
                            }
                            if(searchQuestionsArray[id].range.to !== null){
                                if(searchQuestionsArray[id].range.to > value){
                                    value = searchQuestionsArray[id].range.to
                                }
                            }
                            return points - value
                        }
                        if((searchQuestionsArray[id].questionType === 'MultiSelection')){
                            let newArray = []
                            for(let i = 0; i < searchQuestionsArray[id].selection.length; i++){
                                newArray.push(searchQuestionsArray[id].selection[i].value);
                            }
                            let sortedArray = newArray.sort((a, b) => a - b);
                            let minus = 0;
                            let toSort = 0;
                            if(sortedArray.length < searchQuestionsArray[id].answerQuantity){
                                toSort = sortedArray.length;
                            } else {
                                toSort = searchQuestionsArray[id].answerQuantity;
                            }
                            for(let i = 0; i < toSort; i++){
                                if(sortedArray[sortedArray.length - 1 - i]){
                                    minus += sortedArray[sortedArray.length - 1 - i];
                                }
                            }
                            return points - minus
                        }
                    }

                }
            }

        }



        return points
    }
    function getColors(id){
        let colors = [];
        if(currentTest.questionsScales){
            if(currentTest.questionsScales[id]){
                if(currentTest.questionsScales[id].levels){
                    currentTest.questionsScales[id].levels.map((level, levelId) =>{
                        colors.push(level.color);
                    })
                }
            }
        }
        return colors
    }
    function changeTypeScale(questionId, number, scaleId, scale){
        dispatch(changeLocalTypeScale(number, scaleId));

        let fields = {
            "typeScale": scaleId
        }
        dispatch(updateQuestion(questionId, currentTest._id, fields))
        dispatch(updateLocalQuestionsScales());
    }
    function changeQuestionType(id, type){
        let fields;
        if(type === "range"){
            fields = {
                "range": {
                    "from": "",
                    "to": ""
                },
                "selection": null,
                "questionType": 'Range',
                "answerQuantity": 0
            }
        }
        if(type === "selection"){
            fields = {
                "selection": [],
                "range": null,
                "questionType": 'Selection',
                "answerQuantity": 0
            }
        }
        if(type === "multiSelection"){
            fields = {
                "selection": [],
                "range": null,
                "questionType": 'MultiSelection',
            }
        }
        if(type === "textSelection"){
            fields = {
                "selection": [],
                "range": null,
                "questionType": 'TextSelection'
            }
        }
        if(type === "text"){
            fields = {
                "selection": null,
                "range": null,
                "questionType": 'Text'
            }
        }

        dispatch(updateQuestion(id, currentTest._id, fields))
    }
    function getOffset(el) {
        const rect = el.getBoundingClientRect();
        return {
            left: rect.left + window.scrollX,
            top: rect.top + window.scrollY
        };
    }

function scrollToQuestion(id1) {
    questions[id1].scrollIntoView()
}

let isFirstShort = true;
    let [searchQuestionsArray, setSearchQuestionsArray] = React.useState([])
    function searchQuestions(value) {
        let newArray = []
        if(value !== "" && currentTest.questions){
            currentTest.questions.map((question, questionId) => {

                let toCompare = question.headers.slice(0, value.length);
                if(toCompare.toLowerCase() === value.toLowerCase()){
                    newArray.push(question);
                }
            })
        } else {
            if(currentTest.questions){
                newArray = currentTest.questions;
            } else {
                newArray = []
            }
        }

        setSearchQuestionsArray(newArray)
    }
    useEffect(() => {
            searchQuestions("")
    }, [currentTest.questions])
    return(
        <div className="row questions">
            <div className="leftMenu">
                <div className="search">
                    <img src={search}/>
                    <input onChange={(e) => {searchQuestions(e.target.value)}} type="text" placeholder="Поиск"/>
                </div>
                {searchQuestionsArray ? searchQuestionsArray.map((item, id1) => {
                        if(item.range || item.selection){
                            return <div key={id1} onClick={() =>{scrollToQuestion(id1)}} className={id1 === 0 ? "questionShort active" : "questionShort"}>
                                <label>{'Вопрос ' + (id1 + 1)}</label>
                                <p>{item.headers.length > 25 ? item.headers.slice(0, 24) + '..' : item.headers }</p>
                                <div className="row">
                                    <div className="chips">{item.selection ? 'Выбор варианта': 'Ползунок'}</div>
                                    <div className="colors">
                                        {getColors(id1).map((item, itemOrderId)=>{
                                            isFirstShort = false;
                                            if(item !== null){
                                                return <div key={itemOrderId} style={{backgroundColor: item}} className="colorDot"/>
                                            }
                                        })}
                                    </div>
                                </div>
                            </div>

                        }






                }) : ''}

            </div>
            <div className="rightMenu">
                {currentTest.questions ? currentTest.questions. length > 0 ? currentTest.questions.map((item, id1) => {

                    let dots = [];
                    let isFirst = true;
                    if(item.range){
                        for(let i = item.range.from; i <= item.range.to; i++){
                            dots.push(<div className={isFirst ? 'dot active' :'dot'} onClick={(el) =>{
                                removeActive();
                                el.target.classList.add('active');
                            }

                            }><span>{i}</span></div>)
                            if(isFirst){
                                isFirst = !isFirst;
                            }

                        }
                    }

                    function removeActive(){
                        let dots = document.getElementsByClassName('dot');
                        for(let i = 0; i < dots.length; i++){
                            dots[i].classList.remove('active');
                        }
                    }
                     if(item.range || item.selection || item.questionType === "Text"){
                         return <div className="question_holder">
                             <div className="question">
                                 <img src={sixDots}/>
                                 <DropdownInput id='question_name' questionId={item._id} currentTest={currentTest} id1={id1} type="CHANGE_QUESTION_NAME" value={item.headers} label={"Вопрос " + (id1 + 1)}/>
                                 <div className="inputs selection">
                                     <div className="row">
                                         <div className="levelDropdown questionDropdown">

                                             <p style={ currentTest.questionsScales ? currentTest.questionsScales[id1] ? currentTest.questionsScales[id1].name ? {} : {opacity: 0.5} : {} : {}}>{currentTest.questionsScales ? currentTest.questionsScales[id1] ? currentTest.questionsScales[id1].name ? currentTest.questionsScales[id1].name : 'Выберите шкалу' : 'Выберите шкалу' : 'Выберите шкалу'}</p>
                                             <img src={arrowDown}/>
                                             <div className="levelDropdownContent onlyText">
                                                 {currentTest ? currentTest.scales ? currentTest.scales.map((item2, id2) => {
                                                     if(!item2.integral){
                                                         return <p key={id2} onClick={()=>{changeTypeScale(item._id, id1, item2._id, item2)}}>{item2.name}</p>
                                                     }
                                                 }) : '' : ''}
                                             </div>
                                         </div>
                                         <div className="levelDropdown questionDropdown no-hover">
                                             <p>Осталось баллов</p>
                                             <div className="pointsCounter">{getPoints(id1).toFixed(2)}</div>
                                         </div>
                                     </div>
                                     <div className="row">
                                         <div className="levelDropdown questionDropdown questionChecker no-hover-white">
                                             <p>Интегральный показатель</p>
                                             <div className="checker">
                                                 <input onChange={() => {integralHandler(item._id, currentTest._id, item, id1)}} checked={item.integral ? 'checked' : ''}  type="checkbox"/>
                                                 <div className="line">

                                                 </div>
                                                 <div className="roundCheck">

                                                 </div>
                                             </div>
                                         </div>
                                         <div className="levelDropdown questionDropdown">
                                             <p className="type_text">
                                                 {item.range ?
                                                     <div className="row">
                                                         <img src={lineType}/>
                                                         <p>Линейная шкала</p>
                                                     </div>
                                                     :
                                                     ''
                                                 }
                                                 {item.selection && item.questionType !== "MultiSelection" &&  item.questionType !== "TextSelection" && item.questionType !== "Text"  ?
                                                     <div className="row">
                                                         <img src={oneAnwer}/>
                                                         <p>Один ответ</p>
                                                     </div>

                                                     :
                                                     ''}
                                                 {item.questionType === "MultiSelection" ?
                                                     <div className="row">
                                                         <img src={multiAnswerImg}/>
                                                         <p>Несколько ответов</p>
                                                     </div>

                                                     :
                                                     ''}
                                                 {item.questionType === "TextSelection" ?
                                                     <div className="row">
                                                         <img src={oneAnwer}/>
                                                         <p>Выбор с текстом</p>
                                                     </div>

                                                     :
                                                     ''}
                                                 {item.questionType === "Text" ?
                                                     <div className="row">
                                                         <img src={oneAnwer}/>
                                                         <p>Текстовый</p>
                                                     </div>

                                                     :
                                                     ''}
                                             </p>
                                             <img src={arrowDown}/>
                                             <div className="levelDropdownContent answerType">
                                                 <div onClick={() =>{changeQuestionType(item._id, "range")}} className="row">
                                                     <img src={lineType}/>
                                                     <p>Линейная шкала</p>
                                                 </div>
                                                 <div onClick={() =>{changeQuestionType(item._id, "selection")}} className="row">
                                                     <img src={oneAnwer}/>
                                                     <p>Один ответ</p>
                                                 </div>
                                                 <div onClick={() =>{changeQuestionType(item._id, "multiSelection")}} className="row">
                                                     <img src={multiAnswerImg}/>
                                                     <p>Несколько ответов</p>
                                                 </div>
                                                 <div onClick={() =>{changeQuestionType(item._id, "textSelection")}} className="row">
                                                     <img src={oneAnwer}/>
                                                     <p>Выбор с текстом</p>
                                                 </div>
                                                 <div onClick={() =>{changeQuestionType(item._id, "text")}} className="row">
                                                     <img src={oneAnwer}/>
                                                     <p>Текстовый вопрос</p>
                                                 </div>
                                             </div>
                                         </div>
                                     </div>
                                     {item.questionType === 'MultiSelection' ?
                                         <div className="answersLimiter">
                                             <p>Ограничить возможное количество выбранных ответов:</p>
                                             <div className="row">
                                                 <input onBlur={(e) => {handleQuantityBlur(e, item._id, item)}} onChange={(e) => {handleQuantityChange(e, id1)}} value={item.answerQuantity}  placeholder="Число:"/>
                                             </div>
                                         </div>
                                     : ''}
                                 </div>
                             </div>
                             <div className="addAnswer">
                                 {item.questionType !== 'MultiSelection' && item.selection && item.questionType === 'TextSelection' ?
                                     <div>
                                         {item.selection.map((item2, id2) => {
                                             return <>
                                                 <div key={id2} className="answer row">
                                                     <DropdownInput id='answer_text' currentTest={currentTest} questionId={item._id} selection={item.selection} value={item2.question} id1={id1} id2={id2}
                                                                    type="CHANGE_ANSWER_TEXT"
                                                                    label={id2 + 1 + " вариант ответа"}/>
                                                     <DropdownInput id='answer_value' currentTest={currentTest} questionId={item._id} selection={item.selection} value={item2.value} id1={id1} id2={id2} points={getPoints(id1)}
                                                                    type="CHANGE_ANSWER_VALUE" label="Значение"/>

                                                 </div>
                                                 <div style={{
                                                     paddingLeft: 40,
                                                     paddingRight: 40,
                                                     paddingTop: 20,
                                                     paddingBottom: 20,
                                                     backgroundColor: "white"
                                                 }} className=" row">
                                                     <DropdownInput disabled={item2.isText} id='text_value' currentTest={currentTest} questionId={item._id} selection={item.selection} value={item2.textTitle} id1={id1} id2={id2} points={getPoints(id1)}
                                                                    type="CHANGE_ANSWER_TEXT_TITLE" label="Значение"/>
                                                     <input checked={item2.isText} type="checkbox" onChange={e => {isTextHandler(e, id1, id2, item.selection, item._id, currentTest._id)}}/>
                                                 </div>

                                             </>
                                         })}

                                         <div className="inner">
                                             <div onClick={() =>{addAnswerToQuestion(item._id, item)}} className="row">
                                                 <div className="plusButton">
                                                     <img src={addButton}/>
                                                 </div>
                                                 <p>Добавить ответ</p>
                                             </div>
                                         </div>
                                     </div> :
                                 ''}
                                 {item.questionType !== 'MultiSelection' && item.questionType !== 'TextSelection' && item.selection ?
                                         <div>
                                             {item.selection.map((item2, id2) => {
                                                 return <div key={id2} className="answer row">
                                                         <DropdownInput id='answer_text' currentTest={currentTest} questionId={item._id} selection={item.selection} value={item2.question} id1={id1} id2={id2}
                                                                        type="CHANGE_ANSWER_TEXT"
                                                                        label={id2 + 1 + " вариант ответа"}/>
                                                         <DropdownInput id='answer_value' currentTest={currentTest} questionId={item._id} selection={item.selection} value={item2.value} id1={id1} id2={id2} points={getPoints(id1)}
                                                                        type="CHANGE_ANSWER_VALUE" label="Значение"/>

                                                     </div>
                                             })}

                                             <div className="inner">
                                                 <div onClick={() =>{addAnswerToQuestion(item._id, item)}} className="row">
                                                     <div className="plusButton">
                                                         <img src={addButton}/>
                                                     </div>
                                                     <p>Добавить ответ</p>
                                                 </div>
                                             </div>
                                         </div>

                                     :
                                     ''
                                 }
                                 {item.range ?
                                     <div>

                                         <div className="answer row slider">
                                             {item.range ?  <DropdownInput id='linear_from' questionId={item._id} currentTest={currentTest} value={item.range.from} id1={id1}  type="SET_LINEAR_FROM" label="Диапазон от"/> : ''}
                                             {item.range ? <DropdownInput id='linear_to' questionId={item._id} currentTest={currentTest} value={item.range.to} id1={id1} type="SET_LINEAR_TO" label="До"/> : ''}
                                         </div>
                                         <div className="slider">
                                             <div className="dots">
                                                 {dots}
                                             </div>
                                             <div className="line">

                                             </div>

                                         </div>
                                     </div>
                                     :
                                     ''
                                 }
                                 {item.questionType === 'MultiSelection' ?
                                     <div>
                                         {item.selection.map((item2, id2) => {
                                             return <div key={id2} className="answer row">
                                                 <DropdownInput id='answer_text' currentTest={currentTest} questionId={item._id} selection={item.selection} value={item2.question} id1={id1} id2={id2}
                                                                type="CHANGE_ANSWER_TEXT"
                                                                label={id2 + 1 + " вариант ответа"}/>
                                                 <DropdownInput id='answer_value' currentTest={currentTest} questionId={item._id} selection={item.selection} value={item2.value} id1={id1} id2={id2} points={getPoints(id1)}
                                                                type="CHANGE_ANSWER_VALUE" label="Значение"/>

                                             </div>
                                         })}

                                         <div className="inner">
                                             <div onClick={() =>{addAnswerToQuestion(item._id, item)}} className="row">
                                                 <div className="plusButton">
                                                     <img src={addButton}/>
                                                 </div>
                                                 <p>Добавить ответ</p>
                                             </div>
                                         </div>
                                     </div>

                                     :
                                     ''
                                 }
                                 {item.questionType === 'Text' ?
                                     '':''}

                                 <div className="tools">
                                     <div onClick={() => {copyQuestionCallback(currentTest._id, item)}} className="copyButton">
                                         <img src={copyButton}/>
                                         <p>Создать копию</p>
                                     </div>
                                     <div onClick={() => {deleteQuestionCallback(currentTest._id, item._id)}} className="deleteButton">
                                         <img src={deleteButton}/>
                                         <p>Удалить</p>
                                     </div>
                                 </div>
                             </div>
                         </div>

                     }

                }): '' : ''}
                <div onClick={() =>{dispatch(createQuestion(currentTest._id))}} className="addNewScaleButton addNewQuestionButton">
                    <img src={addButton}/>
                    <div className="addNewScaleButtonDropdown">
                        <div className="button">
                            <div className="text">
                                <p>Добавить шкалу</p>
                                <label>Небольшое описание шкалы, в одном предложении</label>
                            </div>
                            <div className="plus">
                                <img src={addButton}/>
                            </div>
                        </div>
                        <div className="button">
                            <div className="text">
                                <p>Добавить интегральную шкалу</p>
                                <label>Небольшое описание шкалы, в одном предложении</label>
                            </div>
                            <div className="plus">
                                <img src={addButton}/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
