import './App.css';
import {GeneralPage} from "./pages/GeneralPage/GeneralPage";
import MainPage from "./pages/MainPage/MainPage";
import {
    BrowserRouter as Router,
    Routes,
    Route, useLocation
} from "react-router-dom";

import TestCreation from "./pages/TestCreation/TestCreation";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {
    changeLocalIsLogged,
    fetchCountingTypes,
    fetchStaticTags,
    fetchTests,
    loadTests
} from "./Redux/reducers/mainReducer";
import {Helmet} from "react-helmet";




function App() {
   let dispatch = useDispatch()
    function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)===' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }
    let cookies = getCookie('token');
    let isLogged = useSelector(state => state.isLogged)
    function setCookie(name,value,days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    }
    setCookie('token', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MzYxNWE5NDc1YTIzN2JlNmE5NGFkMmEiLCJyb2xlIjoicHN5Y2giLCJpYXQiOjE2Njc0NTI3NjksImV4cCI6MTY2NzUzOTE2OX0.rQG5PQHdyEqqC54Wvp3vEYc3eIWyhOQGICYvPievVsg', 999)
    function eraseCookie(name) {
        document.cookie = name+'=; Max-Age=-99999999;';
    }


     useEffect(() => {
        console.log(cookies)
        if(cookies !== null && cookies !== 'none'){
            dispatch(fetchTests())
            dispatch(fetchCountingTypes())
            dispatch(fetchStaticTags());
        } else {
            eraseCookie('token');
        }
    }, [])

  return (

      <Router>
          <Routes>
              <Route path="/" element={<GeneralPage/>} />
              <Route path="/tests" element={<MainPage/>}>
                  <Route path="published" element={<MainPage/>} />
              </Route>
              <Route path="/test" element={<TestCreation/>}>
                  <Route path=":testId" element={<TestCreation/>}>
                      <Route path=":questions" element={<TestCreation/>}/>
                      <Route path=":scales" element={<TestCreation/>}/>
                  </Route>
              </Route>
          </Routes>
      </Router>
  );
}

export default App;
