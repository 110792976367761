import React from "react";
import DropdownInput from "./DropdownInput";
import DropdownTextfield from "./DropdownTextfield";
import DropdownTags from "./DropdownTags";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    changeLocalIsChild,
    changeLocalOpenOnline, changeLocalOpenPsychologist, changeLocalOpenPublic, changeLocalPublic,
    updateTestField
} from "../../../Redux/reducers/mainReducer";


export default function GeneralTestCreation(props){
    let dispatch = useDispatch();
    let currentTest = props.currentTest;
    let staticTags = useSelector(state => state.main.staticTags)

    const checkTestuHandler = e =>{
        dispatch(changeLocalOpenOnline(!currentTest.openOnline))
        dispatch(changeLocalPublic(!currentTest.public))
        let fields = {
            "openOnline": !currentTest.openOnline,
            "public": !currentTest.openOnline
        }
        dispatch(updateTestField(currentTest._id, fields));

    }
    const childTestuHandler = e =>{
        dispatch(changeLocalIsChild(!currentTest.isChild))
        let fields = {
            "isChild": !currentTest.isChild
        }
        dispatch(updateTestField(currentTest._id, fields));

    }
    const publish = () => {
        dispatch(changeLocalOpenOnline(true))
        dispatch(changeLocalPublic(true))
        let fields = {
            "public": true,
            "openOnline": true
        }
        dispatch(updateTestField(currentTest._id, fields));
    }
    const checkOpenHandler = e =>{
        dispatch(changeLocalOpenPsychologist(!currentTest.openPsychologist))
        let fields = {
            "openPsychologist": !currentTest.openPsychologist
        }
        dispatch(updateTestField(currentTest._id, fields));

    }
    return(
        <div className="row">

            <div className="leftMenu">
                <div className="checkBlock">
                    <h4>Отображать в testu.online</h4>
                    <p>Тест будет отображаться на сайте testu.online</p>
                    <div className="checker">
                        <input onChange={checkTestuHandler} checked={currentTest.openOnline ? 'checked' : ''}  type="checkbox"/>
                        <div className="line">

                        </div>
                        <div className="roundCheck">

                        </div>
                    </div>
                </div>
                <div className="checkBlock">
                    <h4>Тест для ребенка</h4>
                    <p>Этот тест предназначен для ребенка?</p>
                    <div className="checker">
                        <input onChange={childTestuHandler} checked={currentTest.isChild ? 'checked' : ''}  type="checkbox"/>
                        <div className="line">

                        </div>
                        <div className="roundCheck">

                        </div>
                    </div>
                </div>
                {/*<div className="checkBlock">*/}
                {/*    <h4>Открыть для всех психологов</h4>*/}
                {/*    <p>Тест будет доступен для всех психологов на сайте testu.online</p>*/}
                {/*    <div className="checker">*/}
                {/*        <input onChange={checkOpenHandler} checked={currentTest.openPsychologist ? 'checked' : ''}  type="checkbox"/>*/}
                {/*        <div className="line">*/}

                {/*        </div>*/}
                {/*        <div className="roundCheck">*/}

                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <button onClick={() => {window.location.href='https://testu.online/tests/condition/' + currentTest.conditionId}}>Смотреть тест</button>
            </div>
            <div className="rightMenu">
                <div className="innerCreation">
                    <DropdownInput currentTest={currentTest} id='test_title' value={currentTest.name} label={'Название теста'}/>
                    <DropdownInput margin={true} currentTest={currentTest} id='method_author' type="methodAuthor" value={currentTest.methodAuthor} label={'Авторы методики (не отображается у обучающихся)'}/>
                    <DropdownTextfield currentTest={currentTest} id='spec_desc' type="specialistDescription" value={currentTest.desTeacher} label={'Описание для специалистов  (не отображается у обучающихся)'}/>
                    <DropdownTextfield currentTest={currentTest} id='stud_desc' type="studentDescription" value={currentTest.desStudent} label={'Описание для ученика (не обязательное поле)'}/>
                    <DropdownTextfield currentTest={currentTest} id='stud_instr' type="studentInstruction" value={currentTest.desStudents} label={'Инструкция для ученика'}/>
                    <DropdownTags id='test_tags' type="" value="" currentTest={currentTest} tags={currentTest.tags} staticTags={staticTags} label="Добавить теги"/>
                </div>
                {currentTest.public === false ?
                    <button onClick={() => {publish()}} className="publishTest">Опубликовать</button>
                : ''}

            </div>

        </div>
    )
}