import React from "react";
import { SketchPicker } from 'react-color';
import DropdownInput from "./DropdownInput";
import arrowDown from "../img/arrowDown.svg"
import cross from "../img/cross.svg"
import coloredCircle from "../img/coloredCircle.png"
import {useDispatch, useSelector} from "react-redux";
import {
    changeLocalScaleColor,
    startLocalQuestionsScales,
    updateLocalQuestionsScales,
    updateScale
} from "../../../Redux/reducers/mainReducer";
import DropdownTextfield from "./DropdownTextfield";
export default function TestScale(props){

    let dispatch = useDispatch();
    let currentTest = useSelector(state=> state.main.currentTest)

    document.addEventListener('click', (e) =>{
        let checker = 0;

            for(let i = 0; i < e.path.length; i++){

                if(e.path[i].classList){
                    if(!e.path[i].classList.contains('colorDropdown') && !e.path[i].classList.contains('color')){
                        checker++;
                    }
                } else {
                    checker++;
                }
            }
            if(e.path.length === checker){

                checker = 0;
                let toClose = document.getElementsByClassName('colorDropdown');

                for(let i = 0; i < toClose.length; i++){
                    if(toClose[i]){
                        setTimeout(() =>{
                            if(toClose[i]){
                                toClose[i].classList.remove('opened')
                            }
                        }, 1)
                    }
                }
            }




    })

    function openColorPicker(el){
        setTimeout(() =>{
            if(!el.classList.contains('opened')){
                el.classList.add('opened')
            }
        }, 50)

    }
    function closeColorPicker(el){
        setTimeout(() =>{
            if(el.classList.contains('opened')){
                el.classList.remove('opened')
            }
        }, 0)

    }
    function updateColor(color){
        props.currentTest.scales[props.id1].levels.map((level, levelOrderId) => {
            if(level.color === color){
                dispatch(changeLocalScaleColor(props.id1, levelOrderId, ''))
            }
        })
        dispatch(changeLocalScaleColor(props.id1, props.id2,color))
        let levelsFrom = props.currentTest.scales[props.id1].levels;
        levelsFrom[props.id2].color = color;
        let fields = {
            "levels": levelsFrom
        }
        dispatch(updateScale(props.scaleId, props.currentTest._id, fields))

    }
    return(

        <div className="scale">

            <div className="scaleContent">
                <div className="row">
                    <div className="levelDropdown">
                        <div className="color">
                            {props.color ? <div style={{backgroundColor: props.color}} className="color">

                            </div> : <img src={coloredCircle}/>}
                            {/*{props.color ? <div onClick={(el) => openColorPicker(el.currentTarget.nextElementSibling)} style={{backgroundColor: props.color}} className="color">*/}

                            {/*</div> : <img onClick={(el) => openColorPicker(el.currentTarget.nextElementSibling)} src={coloredCircle}/>}*/}
                            {/*/!*<div className="colorDropdown">*!/*/}
                            {/*    <img onClick={(el) => closeColorPicker(el.currentTarget.parentElement)} src={cross}/>*/}
                            {/*    <SketchPicker onChange={(e)=>{updateColor(e.hex)}} color={currentTest.scales[props.id1].levels[props.id2].color ? currentTest.scales[props.id1].levels[props.id2].color : '#000000'}/>*/}
                            {/*    <div className="savedColors">*/}
                            {/*        <p>Сохраненные цвета</p>*/}
                            {/*        <div>*/}
                            {/*            <div onClick={() =>{updateColor('#80CD65')}} style={{backgroundColor: '#80CD65'}} className="color"/>*/}
                            {/*            <div onClick={() =>{updateColor('#FECC62')}} style={{backgroundColor: '#FECC62'}} className="color"/>*/}
                            {/*            <div onClick={() =>{updateColor('#FF5A52')}} style={{backgroundColor: '#FF5A52'}} className="color"/>*/}
                            {/*            <div onClick={() =>{updateColor('#47A0FF')}} style={{backgroundColor: '#47A0FF'}} className="color"/>*/}
                            {/*            <div onClick={() =>{updateColor('#858BFF')}} style={{backgroundColor: '#858BFF'}} className="color"/>*/}
                            {/*        </div>*/}

                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                        <p>Уровень {props.id2 + 1}</p>
                        <img src={arrowDown}/>
                        <div className="levelDropdownContent">
                            <p>Выберите цвет уровня</p>
                            <div className="row">
                                <div onClick={() =>{updateColor('#80CD65')}} style={{backgroundColor: '#80CD65'}} className="color"/>
                                <div onClick={() =>{updateColor('#FECC62')}} style={{backgroundColor: '#FECC62'}} className="color"/>
                                <div onClick={() =>{updateColor('#FF5A52')}} style={{backgroundColor: '#FF5A52'}} className="color"/>
                                <div onClick={() =>{updateColor('#47A0FF')}} style={{backgroundColor: '#47A0FF'}} className="color"/>
                                <div onClick={() =>{updateColor('#858BFF')}} style={{backgroundColor: '#858BFF'}} className="color"/>
                            </div>
                        </div>
                    </div>
                    <DropdownInput scaleId={props.scaleId} currentTest={currentTest} id1={props.id1} id2={props.id2} type="CHANGE_SCALE_FROM" value={props.from} label="Диапазон от"/>
                    <DropdownInput scaleId={props.scaleId} currentTest={currentTest} id1={props.id1} id2={props.id2} type="CHANGE_SCALE_TO"  value={props.to} label="До"/>
                </div>
                <DropdownTextfield margin={true} bigField={true} scaleId={props.scaleId} currentTest={currentTest} id1={props.id1} id2={props.id2} type="CHANGE_SCALE_INTERPRETATION"  value={props.interpretation} label="Интерпретация уровня (не отображается у ученика)"/>
                <DropdownTextfield margin={true} bigField={true} scaleId={props.scaleId} currentTest={currentTest} id1={props.id1} id2={props.id2} type="CHANGE_SCALE_RECOMMENDATION"  value={props.recommendation} label="Рекомендации для уровня (не отображаются у ученика)"/>
            </div>
        </div>
    )
}