import React, {useEffect} from "react";
import {
    changeLocalLinearFrom,
    changeLocalLinearTo, changeLocalMethodAuthor, changeLocalMethodName,
    changeLocalQuestionName,
    changeLocalQuestionQuestion, changeLocalQuestionTextTitle,
    changeLocalQuestionValue,
    changeLocalScaleErrorMessage,
    changeLocalScaleErrorState,
    changeLocalScaleFrom, changeLocalScaleInterpretation,
    changeLocalScaleName, changeLocalScaleRecommendation,
    changeLocalScaleTo,
    changeLocalTestName,
    initLocalScalesErrorsArray,
    updateLocalQuestionsScales,
    updateQuestion,
    updateScale,
    updateTestField,
} from "../../../Redux/reducers/mainReducer";
import {useDispatch, useSelector} from "react-redux";
import roundClose from "../img/roundClose.svg";
import infoDot from "../img/infoError.svg"


export default function DropdownInput(props){
    let isLoadedScale = (state => state.main.isScalesLoaded)
    let dispatch = useDispatch()
    let [error, setError] = React.useState(false)
    useEffect(()=>{
        dispatch(initLocalScalesErrorsArray())
    }, [])
    let errors = useSelector(state => state.main.local_scale_errors)
    function removeQuestion(){
        let selection = props.selection;
        let newSelection = [
                ...selection.slice(0, props.id2),
              ...selection.slice(props.id2 + 1)
        ];
        let fields = {
            "selection": newSelection
        }
        dispatch(updateQuestion(props.questionId, props.currentTest._id, fields))
        dispatch(updateLocalQuestionsScales())
    }
    const handleChangeValue = e => {
        switch (props.type){
            case 'methodName':
                dispatch(changeLocalMethodName(e.target.value));
                break;
            case 'methodAuthor':
                dispatch(changeLocalMethodAuthor(e.target.value));
                break
            case 'noInput':
                break
            case 'SET_LINEAR_FROM':
                dispatch(changeLocalLinearFrom(props.id1, e.target.value))
                dispatch(updateLocalQuestionsScales())
                break
            case 'SET_LINEAR_TO':
                dispatch(changeLocalLinearTo(props.id1, e.target.value))
                dispatch(updateLocalQuestionsScales());
                break
            case 'CHANGE_QUESTION_NAME':
                dispatch(changeLocalQuestionName(props.id1, e.target.value))
                break
            case 'CHANGE_ANSWER_TEXT':
                dispatch(changeLocalQuestionQuestion(props.id1, props.id2, e.target.value))
                break
            case 'CHANGE_ANSWER_TEXT_TITLE':
                dispatch(changeLocalQuestionTextTitle(props.id1, props.id2, e.target.value))
                break
            case 'CHANGE_ANSWER_VALUE':
                dispatch(changeLocalQuestionValue(props.id1, props.id2, e.target.value))
                break
            case 'CHANGE_SCALE_FROM':
                let optionFrom = 0;
                if(e.target.value.includes('.')){
                    let afterDot = e.target.value.split('.')[1];
                    if(afterDot.toString().length === 1){
                        optionFrom = 1;
                        console.log(1);
                    } else if (afterDot.toString().length === 2){
                        optionFrom = 2;
                        console.log(2);
                    } else if (afterDot.toString().length > 2){
                        optionFrom = 3;
                        console.log(3);
                    }
                } else if (e.target.value.includes(',')){
                    let afterComma = e.target.value.split(',')[1];
                    if(afterComma.toString().length === 1){
                        optionFrom = 1;
                    } else if (afterComma.toString().length === 2){
                        optionFrom = 2;
                    } else if (afterComma.toString().length > 2){
                        optionFrom = 3;
                    }
                }
                if(optionFrom === 0){
                    dispatch(changeLocalScaleFrom(props.id1, props.id2, e.target.value));
                } else if(optionFrom === 1){
                    dispatch(changeLocalScaleFrom(props.id1, props.id2, parseFloat(e.target.value).toFixed(1)));
                } else {
                    dispatch(changeLocalScaleFrom(props.id1, props.id2, parseFloat(e.target.value).toFixed(2)));
                }
                break
            case 'CHANGE_SCALE_TO':
                let option = 0;
                if(e.target.value.includes('.')){
                    let afterDot = e.target.value.split('.')[1];
                    if(afterDot.toString().length === 1){
                        option = 1;
                        console.log(1);
                    } else if (afterDot.toString().length === 2){
                        option = 2;
                        console.log(2);
                    } else if (afterDot.toString().length > 2){
                        option = 3;
                        console.log(3);
                    }
                } else if (e.target.value.includes(',')){
                    let afterComma = e.target.value.split(',')[1];
                    if(afterComma.toString().length === 1){
                        option = 1;
                    } else if (afterComma.toString().length === 2){
                        option = 2;
                    } else if (afterComma.toString().length > 2){
                        option = 3;
                    }
                }
                if(option === 0){
                    dispatch(changeLocalScaleTo(props.id1, props.id2, e.target.value))
                    if(props.currentTest.scales[props.id1].levels.length - 1 > props.id2) {
                        if (e.target.value !== '') {
                            dispatch(changeLocalScaleFrom(props.id1, props.id2 + 1, parseFloat(e.target.value) + 1))
                        }
                    }
                } else if(option === 1){
                    dispatch(changeLocalScaleTo(props.id1, props.id2, e.target.value))
                    if(props.currentTest.scales[props.id1].levels.length - 1 > props.id2){
                        if(e.target.value !== ''){
                            dispatch(changeLocalScaleFrom(props.id1, props.id2 + 1, (parseFloat(e.target.value) + 0.1).toFixed(1)))
                        }
                    }
                } else if(option === 2){
                    dispatch(changeLocalScaleTo(props.id1, props.id2, e.target.value))
                    if(props.currentTest.scales[props.id1].levels.length - 1 > props.id2){
                        if(e.target.value !== ''){
                            dispatch(changeLocalScaleFrom(props.id1, props.id2 + 1, (parseFloat(e.target.value) + 0.01).toFixed(2)))
                        }
                    }
                } else {
                    let newVal = parseFloat(e.target.value + 0.01).toFixed(2);
                    dispatch(changeLocalScaleTo(props.id1, props.id2, newVal))
                    if(props.currentTest.scales[props.id1].levels.length - 1 > props.id2){
                        if(e.target.value !== ''){
                            dispatch(changeLocalScaleFrom(props.id1, props.id2 + 1, newVal ))
                        }
                    }
                }
                break
            case 'CHANGE_SCALE_NAME':
                dispatch(changeLocalScaleName(props.id1, e.target.value))
                dispatch(updateLocalQuestionsScales())
                break
            case 'CHANGE_SCALE_INTERPRETATION':
                dispatch(changeLocalScaleInterpretation(props.id1, props.id2, e.target.value))
                dispatch(updateLocalQuestionsScales())
                break
            case 'CHANGE_SCALE_RECOMMENDATION':
                dispatch(changeLocalScaleRecommendation(props.id1, props.id2, e.target.value))
                dispatch(updateLocalQuestionsScales())
                break
            default:
                dispatch(changeLocalTestName(e.target.value))
                break
        }
    }
    const handleChangeBlur = e => {
        let fields;
        switch (props.type){
            case 'SET_LINEAR_FROM':
                fields = {
                    "range": {
                        "from": e.target.value,
                        "to": props.currentTest.questions[props.id1].range.to
                    }
                }
                dispatch(updateQuestion(props.questionId, props.currentTest._id, fields))
                break
            case 'SET_LINEAR_TO':
                fields = {
                    "range": {
                        "from": props.currentTest.questions[props.id1].range.from,
                        "to": e.target.value
                    }
                }
                dispatch(updateQuestion(props.questionId, props.currentTest._id, fields))
                break
            case 'CHANGE_QUESTION_NAME':
                fields = {
                    "headers": e.target.value
                }
                dispatch(updateQuestion(props.questionId, props.currentTest._id, fields))
                break
            case 'CHANGE_ANSWER_TEXT':
                let selection = props.selection;
                selection[props.id2].question = e.target.value;
                fields = {
                    "selection": selection
                }
                dispatch(updateQuestion(props.questionId, props.currentTest._id, fields))
                break
            case 'CHANGE_ANSWER_TEXT_TITLE':
                let selectionTitle = props.selection;
                selectionTitle[props.id2].textTitle = e.target.value;
                fields = {
                    "selection": selectionTitle
                }
                dispatch(updateQuestion(props.questionId, props.currentTest._id, fields))
                break
            case 'CHANGE_ANSWER_VALUE':
                let selection2 = props.selection;
                selection2[props.id2].value = e.target.value;
                fields = {
                    "selection": selection2
                }
                dispatch(updateQuestion(props.questionId, props.currentTest._id, fields))
                break
            case 'CHANGE_SCALE_FROM':
                let levelsFrom = props.currentTest.scales[props.id1].levels;
                let optionFrom = 0;
                if(e.target.value.includes('.')){
                    let afterDot = e.target.value.split('.')[1];
                    if(afterDot.toString().length === 1){
                        optionFrom = 1;
                        console.log(1);
                    } else if (afterDot.toString().length === 2){
                        optionFrom = 2;
                        console.log(2);
                    } else if (afterDot.toString().length > 2){
                        optionFrom = 3;
                        console.log(3);
                    }
                } else if (e.target.value.includes(',')){
                    let afterComma = e.target.value.split(',')[1];
                    if(afterComma.toString().length === 1){
                        optionFrom = 1;
                    } else if (afterComma.toString().length === 2){
                        optionFrom = 2;
                    } else if (afterComma.toString().length > 2){
                        optionFrom = 3;
                    }
                }
                if(optionFrom === 0){
                    levelsFrom[props.id2].range.from = e.target.value;
                } else if(optionFrom === 1){
                    levelsFrom[props.id2].range.from = parseFloat(e.target.value).toFixed(1);
                } else {
                    levelsFrom[props.id2].range.from = parseFloat(e.target.value).toFixed(2);
                }
                fields = {
                    "levels": levelsFrom
                }
                fieldsChecker()
                dispatch(updateScale(props.scaleId, props.currentTest._id, fields))
                break
            case 'CHANGE_SCALE_TO':
                let levels = props.currentTest.scales[props.id1].levels;
                let option = 0;
                if(e.target.value.includes('.')){
                    let afterDot = e.target.value.split('.')[1];
                    if(afterDot.toString().length === 1){
                        option = 1;
                    } else if (afterDot.toString().length === 2){
                        option = 2;
                    } else if (afterDot.toString().length > 2){
                        option = 3;
                    }
                } else if (e.target.value.includes(',')){
                    let afterComma = e.target.value.split(',')[1];
                    if(afterComma.toString().length === 1){
                        option = 1;
                    } else if (afterComma.toString().length === 2){
                        option = 2;
                    } else if (afterComma.toString().length > 2){
                        option = 3;
                    }
                }
                if(option === 0){
                    levels[props.id2].range.to = e.target.value;
                    if(props.currentTest.scales[props.id1].levels.length - 1 > props.id2) {
                        if (e.target.value !== '') {
                            levels[props.id2 + 1].range.from = parseFloat(e.target.value) + 1;
                        }
                    }
                } else if(option === 1){
                    levels[props.id2].range.to = e.target.value;
                    if(props.currentTest.scales[props.id1].levels.length - 1 > props.id2){
                        if(e.target.value !== ''){
                            levels[props.id2 + 1].range.from = (parseFloat(e.target.value)+ 0.1).toFixed(1);
                        }
                    }
                } else if(option === 2){
                    levels[props.id2].range.to = e.target.value;
                    if(props.currentTest.scales[props.id1].levels.length - 1 > props.id2){
                        if(e.target.value !== ''){
                            levels[props.id2 + 1].range.from = (parseFloat(e.target.value) + 0.01).toFixed(2);
                        }
                    }
                } else {
                    let newVal = parseFloat(e.target.value + 0.01).toFixed(2);
                    levels[props.id2].range.to = e.target.value;
                    if(props.currentTest.scales[props.id1].levels.length - 1 > props.id2){
                        if(e.target.value !== ''){
                            levels[props.id2 + 1].range.from = newVal;
                        }
                    }

                }


                fields = {
                    "levels": levels
                }
                dispatch(updateScale(props.scaleId, props.currentTest._id, fields))
                fieldsChecker()
                break
            case 'CHANGE_SCALE_NAME':
                fields = {
                    "name": e.target.value
                }
                dispatch(updateScale(props.scaleId, props.currentTest._id, fields))
                break
            case 'CHANGE_SCALE_INTERPRETATION':
                let levelsI = props.currentTest.scales[props.id1].levels;
                levelsI[props.id2].interpretation = e.target.value
                fields = {
                    "levels": levelsI
                }
                dispatch(updateScale(props.scaleId, props.currentTest._id, fields))
                break;
            case 'CHANGE_SCALE_RECOMMENDATION':
                let levelsR = props.currentTest.scales[props.id1].levels;
                levelsR[props.id2].recommendation = e.target.value
                fields = {
                    "levels": levelsR
                }
                dispatch(updateScale(props.scaleId, props.currentTest._id, fields))
                break;
            case 'methodName':
                fields = {
                    "methodName": e.target.value
                }
                dispatch(updateTestField(props.currentTest._id, fields))
                break;
            case 'methodAuthor':
                fields = {
                    "methodAuthor": e.target.value
                }
                dispatch(updateTestField(props.currentTest._id, fields))
                break
            default:
                fields = {
                    "name": e.target.value
                }
                dispatch(updateTestField(props.currentTest._id, fields))

                break
        }

    }

    useEffect(()=>{
        fieldsChecker()
    },[])


function fieldsChecker(){
    let errorsData = []
    if(props.type === "CHANGE_SCALE_FROM" || props.type === "CHANGE_SCALE_TO" ) {
        props.currentTest.scales.map((scale, scaleOrderId) => {

            let scaleData = []
            scale.levels.map((level, levelOrderId) => {
                scaleData.push([level.range.from, level.range.to])
            })
            errorsData.push(scaleData)

        })
        errorsData.map((errorData, errorDataId) => {
            for(let i = 0; i < errorData.length; i++){
                if(errorData[i][1] && errorData[i][0]){
                    if(errorData[i][0] > errorData[i][1]){
                        dispatch(changeLocalScaleErrorState(errorDataId, i, 'to', true));
                        dispatch(changeLocalScaleErrorMessage(errorDataId, i, 'to', 'Значения должны идти в порядки возрастания!'));
                    } else {
                        dispatch(changeLocalScaleErrorState(errorDataId, i, 'to', false));
                        dispatch(changeLocalScaleErrorMessage(errorDataId, i, 'to', ''));
                    }
                }

                if(i < errorData.length - 1){
                    console.log((errorData[i + 1][0] - errorData[i][1]).toFixed(2))
                    if(errorData[i][1] >= errorData[i + 1][0]){
                        if(errorData[i][1] && errorData[i + 1][0]){
                            dispatch(changeLocalScaleErrorState(errorDataId, i, 'to', true));
                            dispatch(changeLocalScaleErrorState(errorDataId, i + 1, 'from', true));
                            dispatch(changeLocalScaleErrorMessage(errorDataId, i, 'to', 'Значения должны идти в порядки возрастания!'));
                            dispatch(changeLocalScaleErrorMessage(errorDataId, i + 1, 'from', 'Значения должны идти в порядки возрастания!'));
                        }
                    } else if(errorData[i + 1][0] - errorData[i][1] > 1){
                        if(errorData[i][1] && errorData[i + 1][0]){
                            dispatch(changeLocalScaleErrorState(errorDataId, i, 'to', true));
                            dispatch(changeLocalScaleErrorState(errorDataId, i + 1, 'from', true));
                            dispatch(changeLocalScaleErrorMessage(errorDataId, i, 'to', 'Разница диапазона между уровнями должна быть равна 1!'));
                            dispatch(changeLocalScaleErrorMessage(errorDataId, i + 1, 'from', 'Разница диапазона между уровнями должна быть равна 1!'));
                        }
                    }  else if(((errorData[i + 1][0] - errorData[i][1] === 1 || parseFloat((errorData[i + 1][0] - errorData[i][1]).toFixed(1)) === 0.1 || parseFloat((errorData[i + 1][0] - errorData[i][1]).toFixed(2)) === 0.01) && errorData[i][1] < errorData[i + 1][0] && errorData[i][0] < errorData[i][1]) || (!errorData[i + 1][0] || !errorData[i][1])){
                        dispatch(changeLocalScaleErrorState(errorDataId, i, 'to', false));
                        dispatch(changeLocalScaleErrorState(errorDataId, i + 1, 'from', false));
                        dispatch(changeLocalScaleErrorMessage(errorDataId, i + 1, 'from', ''));
                        dispatch(changeLocalScaleErrorMessage(errorDataId, i, 'to', ''));
                    }
                }
            }
        })
    }
}


    if(props.type === "CHANGE_ANSWER_TEXT"){
        return <div onClick={(e)=>{e.currentTarget.firstElementChild.focus()}} className="dropdownInput">
            <input onBlur={handleChangeBlur} value={props.value} onChange={handleChangeValue} placeholder=" " type="text"/>
            <label>{props.label}</label>
            <img className="infoDot" src={infoDot} alt=""/>
            {props.type === "CHANGE_ANSWER_TEXT" || props.type === "CHANGE_ANSWER_VALUE" ? <div onClick={() =>{removeQuestion()}} className="roundClose">
                <img src={roundClose}/>
            </div> : ''}
            <div className="infoBlock">

            </div>
        </div>
    } else if(props.type === "CHANGE_SCALE_TO"){
        return <div onClick={(e)=>{e.currentTarget.firstElementChild.focus()}} className={errors ? errors[props.id1] ? errors[props.id1][props.id2] ? errors[props.id1][props.id2].to.error ? "dropdownInput value error": "dropdownInput value" : "dropdownInput value" : "dropdownInput value" : "dropdownInput value"}>
            <input onBlur={handleChangeBlur} value={props.value} onChange={handleChangeValue} placeholder=" " type="text"/>
            <label>{props.label}</label>
            <img className="infoDot" src={infoDot} alt=""/>
            {props.type === "CHANGE_ANSWER_TEXT" || props.type === "CHANGE_ANSWER_VALUE" ? <div onClick={() =>{removeQuestion()}} className="roundClose">
                <img src={roundClose}/>
            </div> : ''}
            <div className="infoBlock">
                {errors ? errors[props.id1] ? errors[props.id1][props.id2] ? errors[props.id1][props.id2].to.message : '' : '' : '' }
            </div>
        </div>
    } else if (props.type === "CHANGE_SCALE_FROM"){
        if(props.id1 === 0 && props.id2 === 0){
            return <div onClick={(e)=>{e.currentTarget.firstElementChild.focus()}} className={errors ? errors[props.id1] ? errors[props.id1][props.id2] ?  errors[props.id1][props.id2].from.error ? "dropdownInput value error": "dropdownInput value" : "dropdownInput value": "dropdownInput value" : "dropdownInput value"}>
                <input  onBlur={handleChangeBlur} value={props.value} placeholder=" " onChange={handleChangeValue} type="text"/>
                <label>{props.label}</label>
                <img className="infoDot" src={infoDot} alt=""/>
                {props.type === "CHANGE_ANSWER_TEXT" || props.type === "CHANGE_ANSWER_VALUE" ? <div onClick={() =>{removeQuestion()}} className="roundClose">
                    <img src={roundClose}/>
                </div> : ''}
                <div className="infoBlock">
                    {errors ? errors[props.id1] ? errors[props.id1][props.id2] ? errors[props.id1][props.id2].from.message : '' : '' : ''}
                </div>
            </div>
        } else {
            return <div onClick={(e)=>{e.currentTarget.firstElementChild.focus()}} className={errors ? errors[props.id1] ?  errors[props.id1][props.id2] ? errors[props.id1][props.id2].from.error ? "dropdownInput value error": "dropdownInput value" : "dropdownInput value": "dropdownInput value" : "dropdownInput value"}>
                <input onBlur={handleChangeBlur} value={props.value} onChange={handleChangeValue} placeholder=" " type="text"/>
                <label>{props.label}</label>
                <img className="infoDot" src={infoDot} alt=""/>
                {props.type === "CHANGE_ANSWER_TEXT" || props.type === "CHANGE_ANSWER_VALUE" ? <div onClick={() =>{removeQuestion()}} className="roundClose">
                    <img src={roundClose}/>
                </div> : ''}
                <div className="infoBlock">
                    {errors ? errors[props.id1] ? errors[props.id1][props.id2] ? errors[props.id1][props.id2].from.message : '' : '' : ''}
                </div>
            </div>
        }
    } else {
        return <div  style={props.margin === true ? props.bigField === true ? {marginTop: '30px', width: '100%'} : {marginTop: '30px',} : props.bigField === true ? {width: '100%'} : {}} onClick={(e)=>{e.currentTarget.firstElementChild.focus()}} className="dropdownInput value">
            <input disabled={!props.disabled && props.type==="CHANGE_ANSWER_TEXT_TITLE"} style={props.margin === true ? {fontSize: '16px'} : {}} onBlur={handleChangeBlur} value={props.value} onChange={handleChangeValue} placeholder=" " type="text"/>
            <label>{props.label}</label>
            <img className="infoDot" src={infoDot} alt=""/>
            {props.type === "CHANGE_ANSWER_TEXT" || props.type === "CHANGE_ANSWER_VALUE" ? <div onClick={() =>{removeQuestion()}} className="roundClose">
                <img src={roundClose}/>
            </div> : ''}
            <div className="infoBlock">

            </div>
        </div>
    }


}
