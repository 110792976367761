import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import appleTouchIcon from "./favicons/apple-touch-icon.png"
import favicon32x32 from "./favicons/favicon-32x32.png"
import favicon16x16 from "./favicons/favicon-16x16.png"
import siteWebManifest from "./favicons/site.webmanifest"
import safariPinnedTab from "./favicons/safari-pinned-tab.svg"

import {Provider, useDispatch} from "react-redux";
import {store} from "./Redux";
import {Helmet} from "react-helmet";

ReactDOM.render(
    <Provider store={store}>
      <React.StrictMode>
          <Helmet>
              <title>Constructor</title>
              <link rel="icon" href={favicon32x32}/>
              <link rel="apple-touch-icon" sizes="60x60" href={appleTouchIcon}/>
              <link rel="icon" type="image/png" sizes="32x32" href={favicon32x32}/>
              <link rel="icon" type="image/png" sizes="16x16" href={favicon16x16}/>
              <link rel="manifest" href={siteWebManifest} />
              <link rel="mask-icon" href={safariPinnedTab} color="#ffbe4e"/>
              <meta name="msapplication-TileColor" content="#ffffff"/>
              <meta name="theme-color" content="#ffffff" />
          </Helmet>
        <App />
      </React.StrictMode>
    </Provider>,
  document.getElementById('root')
);