import React from "react";

import infoImg from "../img/info.svg"
import smallCross from "../img/smallCross.svg"
import {useDispatch} from "react-redux";
import {changeLocalTags, createStaticTag, updateTestField} from "../../../Redux/reducers/mainReducer";
import infoDot from "../img/infoError.svg";

export default function DropdownTags(props){

    let dispatch = useDispatch();




    function removeTag(id, e){
        let tags = props.currentTest.tags;
        tags.splice(id, 1)
        let tagsIds = [];
        for(let i =0; i < tags.length; i++){
            tagsIds.push(tags[i]._id)
        }
        let fields = {
            "tags": tagsIds
        }
        dispatch(changeLocalTags(tags))
        dispatch(updateTestField(props.currentTest._id, fields))
    }
    function addTagToTest(newTag){
        let newTags;
        if(props.currentTest.tags){
            newTags = props.currentTest.tags;
        } else {
            newTags = []
        }
        let isDuplicated = false;
        for(let i = 0; i < newTags.length; i++){
            if(newTags[i].name === newTag.name){
                isDuplicated = true;
            }
        }
        if(!isDuplicated){
            newTags.push(newTag);
            let tagsIds = [];
            for(let i = 0; i < newTags.length; i++){
                tagsIds.push(newTags[i]._id);
            }
            let fields = {
                "tags": tagsIds
            }
            dispatch(changeLocalTags(newTags))
            dispatch(updateTestField(props.currentTest._id, fields))
        }
    }
    function addTag(e){
        e.preventDefault();
        if (e.keyCode === 13 && e.target.value.length <= 25) {
            let isDuplicated = false;
            for(let i = 0; i < props.tags.length; i++){
                if(props.tags[i].name === e.target.value){
                    isDuplicated = true;
                }
            }
            if(!isDuplicated){
                dispatch(createStaticTag(props.currentTest._id, e.target.value, props.currentTest.tags))
            }

            e.target.value = '';
            e.currentTarget.blur();
        }
    }
    let [isError, setIsError] = React.useState(false)
    function handleInput(e){
        if(e.target.value.length > 25){
            setIsError(true)
        } else {
            setIsError(false);
        }
    }
    function clickHandler(e){

       if(!e.target.classList.contains('closer')){
           e.currentTarget.firstElementChild.focus()
       }
       if(e.target.classList.contains('item')){
           e.currentTarget.firstElementChild.blur()
       }


    }
    return(
        <div onClick={e => clickHandler(e)} className={isError ? "dropdownInput dropdownTags error" :"dropdownInput dropdownTags"}>
            <input onChange={handleInput} autoComplete="off" id={props.id} className="dropdownTagInput" onKeyUp={ e => { addTag(e);}} placeholder=" " type="text"/>
            <label>{props.label}</label>
            {props.tags ?
            <div className="chips">
                {props.tags.map((item, id1) => {
                    return (
                        <div key={id1} className="chip">
                            <img className="closer" alt="" onClick={e=>{removeTag(id1, e)}} src={smallCross}/>
                            <p>{item.name}</p>
                        </div>
                    )
                })}
            </div> :''}
            <img className="infoDot" src={infoDot} alt=""/>
            <div className="info basicInfo">
                <img alt="" src={infoImg}/>
            </div>
            <div className="infoBlock">
                Длина тега должна быть менее 25 символов
            </div>
            <div className="content">
                {props.staticTags.map((item, id1) => {
                    return (
                        <p key={id1}  className="item" onClick={() =>{addTagToTest(item)}}>{item.name}</p>
                    )
                })}
            </div>
        </div>
    )

}


